import { Box, Flex, Show, Skeleton, SkeletonText } from "@chakra-ui/react";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import { useContext } from "react";
import { DisplayContext } from "../../context/DisplayContextProvider";
import { ParameterContext } from "../../context/ParameterContextProvider";
const RenderSearchFormResponsive = () => {
  return <Box boxShadow="0 4px 12px 2px rgb(0 0 0 / 21%)" pb={6} pt={[2, 2, 2, 6]} px={4} bg="white" backgroundClip="border-box" borderRadius="10px" data-sentry-element="Box" data-sentry-component="RenderSearchFormResponsive" data-sentry-source-file="StartForm.tsx">
      <SkeletonText noOfLines={1} mb={0} skeletonHeight="40px" data-sentry-element="SkeletonText" data-sentry-source-file="StartForm.tsx" />
      <Box my="1" data-sentry-element="Box" data-sentry-source-file="StartForm.tsx"></Box>
      <Flex direction="column" data-sentry-element="Flex" data-sentry-source-file="StartForm.tsx">
        <Box mb={3} data-sentry-element="Box" data-sentry-source-file="StartForm.tsx">
          <Skeleton height="60px" data-sentry-element="Skeleton" data-sentry-source-file="StartForm.tsx" />
        </Box>
        <Box mb={3} data-sentry-element="Box" data-sentry-source-file="StartForm.tsx">
          <Skeleton height="60px" data-sentry-element="Skeleton" data-sentry-source-file="StartForm.tsx" />
        </Box>
        <Box mb={3} data-sentry-element="Box" data-sentry-source-file="StartForm.tsx">
          <Skeleton height="60px" data-sentry-element="Skeleton" data-sentry-source-file="StartForm.tsx" />
        </Box>
        <Box mb={10} data-sentry-element="Box" data-sentry-source-file="StartForm.tsx">
          <Skeleton height="60px" data-sentry-element="Skeleton" data-sentry-source-file="StartForm.tsx" />
        </Box>
        <Box data-sentry-element="Box" data-sentry-source-file="StartForm.tsx">
          <Skeleton height="48px" data-sentry-element="Skeleton" data-sentry-source-file="StartForm.tsx" />
        </Box>
      </Flex>
    </Box>;
};
const RenderWidgetHeader = () => {
  return <Box boxShadow="0 4px 12px 2px rgb(0 0 0 / 21%)" pb={4} pt={[2, 2, 2, 6]} px={4} bg="white" backgroundClip="border-box" borderRadius="10px" data-sentry-element="Box" data-sentry-component="RenderWidgetHeader" data-sentry-source-file="StartForm.tsx">
      <Box p="4px" data-sentry-element="Box" data-sentry-source-file="StartForm.tsx">
        <SkeletonText noOfLines={1} mb={3} skeletonHeight="40px" data-sentry-element="SkeletonText" data-sentry-source-file="StartForm.tsx" />
        <Flex direction="column" data-sentry-element="Flex" data-sentry-source-file="StartForm.tsx">
          <Skeleton height="60px" data-sentry-element="Skeleton" data-sentry-source-file="StartForm.tsx" />
        </Flex>
      </Box>
    </Box>;
};
const DynamicSearchFormResponsive = dynamic(() => import("../SearchForTrainingsAndJobs/components/SearchFormResponsiveHomePage"), {
  loading: () => <RenderSearchFormResponsive />
});
const DynamicWidgetHeader = dynamic(() => import("../WidgetHeader/WidgetHeaderHomePage"), {
  loading: () => <RenderWidgetHeader />
});
const StartForm = () => {
  const router = useRouter();
  const {
    setFormValues
  } = useContext(DisplayContext);
  const {
    setShouldExecuteSearch
  } = useContext(ParameterContext);
  const handleSearchSubmit = values => {
    const {
      job,
      location,
      radius,
      diploma
    } = values;
    setFormValues({
      job,
      location,
      radius,
      diploma
    });
    setShouldExecuteSearch(true);
    router.push("/recherche");
  };
  return <>
      <Show below="lg" data-sentry-element="Show" data-sentry-source-file="StartForm.tsx">
        <DynamicSearchFormResponsive handleSearchSubmit={handleSearchSubmit} isHome={true} showResultList={() => {}} data-sentry-element="DynamicSearchFormResponsive" data-sentry-source-file="StartForm.tsx" />
      </Show>
      <Show above="lg" data-sentry-element="Show" data-sentry-source-file="StartForm.tsx">
        <DynamicWidgetHeader handleSearchSubmit={handleSearchSubmit} isHome={true} data-sentry-element="DynamicWidgetHeader" data-sentry-source-file="StartForm.tsx" />
      </Show>
    </>;
};
export default StartForm;